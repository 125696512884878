/* global window, document */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/html.css';

const scrollTo = id => () => {
  const el = document.querySelector(id);
  if (el)
    return window.scrollTo(
      0,
      el.offsetTop + (window.innerWidth > 768 ? 140 : 0)
    );
  return false;
};

const onRouteUpdate = ({ location: { hash } }) => {
  if (hash) {
    window.setTimeout(scrollTo(hash), 10);
  }
};

const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);
  const { hash } = location;
  if (hash) {
    return window.setTimeout(scrollTo(hash), 10);
  }
  window.scrollTo(...(currentPosition || [0, 0]));

  return false;
};

export { onRouteUpdate, shouldUpdateScroll };
